import { Button } from "@/components/ui/button";
import { MoveLeft } from "lucide-react";
import Link from "next/link";


export default function PageNotFound() {
    return (
        <section className="w-full h-screen bg-white overflow-hidden relative">
            <header className="w-full h-[70px] flex items-center px-5 md:px-7">
                <img
                    src="/logo.png"
                    alt="Ventia logo"
                    className={`w-[115px] transition-transform duration-100 flex`}
                />
            </header>
            <div className="h-[calc(100%-102px)] z-20 flex px-4 flex-col items-center justify-center text-center">
                <div className="relative">
                    <img src={'/illustrations/page-not-found.svg'} className="w-[300px] z-10" />
                    <img
                        src={'/illustrations/uranus.svg'}
                        className="w-[90px] z-10 absolute left-8 top-2 animate-bounce-slow"
                    />
                </div>
                <div className="flex flex-col items-center">
                    <h1 className="font-bold text-[25px] md:text-[30px] text-gray-900 z-10">
                        ¡Ups! Página no encontrada
                    </h1>
                    <p className="text-gray-600 text-[14px] md:text-[17px] z-10 mt-1">
                        Parece que la página que estás buscando no existe o ha sido movida.
                    </p>
                    <Link href={'/'}>
                        <Button
                            variant="link"
                            className="flex items-center group md:text-sm text-[12px] mt-8 z-10 "
                        >
                            <MoveLeft size={20} className="mr-2 group-hover:-translate-x-2 duration-100" />
                            Volver al inicio
                        </Button>
                    </Link>
                </div>
            </div>
            {/* <DashedArrow className="absolute top-10 z-[1] -left-5 text-gray-300 w-[280px]" />
            <DashedArrow className="absolute bottom-2 z-[1] -right-5 text-gray-300 rotate-180 w-[280px]" /> */}
        </section>
    )
}

